import { Suspense, useEffect, useState } from 'react';
import './App.css';
import axios from 'axios'

function App() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadedPer, setLoadedPer] = useState('')
  // const url = 'http://localhost:3001'
  const url = 'https://upload.sg-developer.com/api'

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('image', selectedFile);

      const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setLoadedPer(`${loaded} bytes of ${total} bytes. ${percent}%`)
        }
      };
      axios.post(`${url}/uploadImage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      })
        .then(response => {
          console.log(response)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  useEffect(() => {
    console.log(selectedFile)
  }, [selectedFile])

  return (
    <div className="">
      <div>
        <Suspense fallback={<div>isLoading</div>}>
          <input type="file" onChange={handleFileChange} />
          <button onClick={handleUpload}>Upload</button>
          {loadedPer}
        </Suspense>
      </div>
    </div>
  );
}

export default App;
